import axios from "axios";
import moment from "moment";
import config from "../api/api.config";

const api = config.API_URL;

let ready = false;
export function setUpInterceptors(getAppState, setAppState) {
  console.log("setting up interceptors");
  const interceptor = axios.interceptors.request.use(
    async function (config) {
      console.log("running interceptor", config, getAppState());
      if (config.__skipAuth) {
        return config;
      }
      let state = getAppState();
      if (state.tokens) {
        let access = state.tokens.access;
        let expires = moment(access.expires);
        if (expires.diff(moment()) > 0) {
          config.headers["Authorization"] = `Bearer ${access.token}`;
        } else {
          let refresh = state.tokens.refresh;
          expires = moment(refresh.expires);
          if (expires.diff(moment()) > 0) {
            try {
              let res = await axios.post(
                api + "/auth/refreshTokens",
                {
                  refreshToken: refresh.token,
                },
                {
                  __skipAuth: true,
                }
              );

              if (res.status === 200) {
                setAppState({
                  ...state,
                  tokens: res.data,
                });
                access = res.data.access;
                config.headers["Authorization"] = `Bearer ${access.token}`;
                return config;
              } else {
                throw new Error(
                  "access token is expired and couldnt get it refreshed"
                );
              }
            } catch (e) {
              setAppState({
                ...state,
                tokens: null,
                user: null,
              });
            }
          } else {
            setAppState({
              ...state,
              tokens: null,
              user: null,
            });
            throw new Error("both access token and refresh token are expired");
          }
        }
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  ready = true;

  return function unsetInterceptors() {
    console.log("removing interceptor");
    ready = false;
    axios.interceptors.request.eject(interceptor);
  };
}

// Add a request interceptor

export default async function getAxios() {
  if (ready) {
    return axios;
  } else {
    await new Promise((resolve) => setTimeout(resolve, 10));
    return getAxios();
  }
}
